import React from 'react'

import { Container, Row } from '@/components/base/gridview'

import Video from '../video'

import { VideosListProps } from './types'
import { useMobile } from '@/core/hooks'
import { useApp } from '@/core/contexts/app'
import { useWidth } from '@/core/hooks/useWidthResize'

const VideoList: React.FunctionComponent<VideosListProps> = ({
  videos = [],
  isDetail = false,
}) => {
  const app = useApp()
  const width = useWidth()
  const result = width < 1000 ? videos.slice(0, 3) : videos

  return (
    <div className="video-list">
      <Container size="extended">
        <Row>
          {result.map((item, index) => (
            <React.Fragment key={index}>
              <Video {...item} isDetail={isDetail} />
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default VideoList
