import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { usePopup } from '@/core/contexts/popup'

import Image from '@/components/base/image'
import { Column } from '@/components/base/gridview'
import Button from '@/components/base/button'
import Icon from '@/components/base/icon'

import { VideoDetailStatus } from './types'

const Video: React.FunctionComponent<VideoDetailStatus> = ({
  title,
  desc,
  video,
  image,
  isDetail,
}) => {
  const router = useRouter()
  const { popup, setPopup } = usePopup()
  const { popupData, setPopupData } = usePopup()
  const { queryParams, setQueryParams } = usePopup()

  useEffect(() => {
    const urlParam = router.query.url

    if (urlParam) {
      if (Array.isArray(urlParam)) {
        setQueryParams({
          url: urlParam[0],
        })
      } else {
        setQueryParams({
          url: urlParam,
        })
      }
    }
  }, [])

  useEffect(() => {
    if (queryParams.url === title) {
      setPopupData({
        title: title || '',
        desc: desc || '',
        image: image?.src || '',
        video: video.src || '',
      })
      setPopup(true)
    }
  }, [queryParams])

  const handlePopup = () => {
    setQueryParams({
      url: title || '',
    })

    setPopupData({
      title: title || '',
      desc: desc || '',
      image: image?.src || '',
      video: video.src || '',
    })
    setPopup(true)
  }

  return (
    <Column
      className="video"
      xs={{ size: 12 }}
      sm={{ size: 12 }}
      md={{ size: 6 }}
      lg={{ size: 4 }}
      xl={{ size: 3 }}
    >
      <Button onClick={handlePopup}>
        <figure>
          <Image fluid {...image} alt={title} />
          <Icon name="play-circle-filled" size={'xlarge'} />
        </figure>
        {isDetail && (
          <div className="detail">
            <div className="title">{title}</div>
            {desc && <div dangerouslySetInnerHTML={{ __html: desc }} />}
          </div>
        )}
      </Button>
    </Column>
  )
}

export default Video
